import { render, staticRenderFns } from "./RegistryMirrors.vue?vue&type=template&id=7f07724f&"
import script from "./RegistryMirrors.vue?vue&type=script&lang=js&"
export * from "./RegistryMirrors.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports